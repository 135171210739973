import { Link } from "gatsby"
import React from "react"

import { CDNBASE } from "../utils/maps"
import st from "./css/footer.module.scss"

const Footer = ({ isMobile, isWx }) => {
  const tip = !isMobile ? "微信扫码" : isWx ? "长按识别" : "长按保存图片"
  return (
    <footer className={st.footer}>
      <div className={st.cols}>
        <dl>
          <dt>常用链接</dt>
          <dd>
            <p>
              <Link to="/">酷课功能介绍</Link>
            </p>
            <p>
              <Link to="/cases/">用户使用案例</Link>
            </p>
            <p>
              <Link to="/list/upgrade/2020-12-31-upgrad-logs/">更新日志</Link>
            </p>
            <p>
              <Link to="/FQ.html">常见问题</Link>
            </p>
          </dd>
        </dl>
        <dl>
          <dt>关于我们</dt>
          <dd>
            <p>
              <Link to="/joinus.html">加入我们</Link>
            </p>
            <p>
              <Link to="/contactus.html">联系我们</Link>
            </p>
            <p>
              <Link to="/agreement.html">酷课服务协议</Link>
            </p>
            <p>
              <Link to="/promise.html">酷课服务承诺</Link>
            </p>
          </dd>
        </dl>
        <dl>
          <dt>{isWx ? "开始免费试用" : "酷课公众号"}</dt>
          <dd className={st.scanTip}>{tip}</dd>
          <dd>
            <img alt="酷课公众号" src={CDNBASE + "/src/qrcode/gzh_dark.gif"} />
          </dd>
        </dl>
        {!isMobile && (
          <dl>
            <dt>学员端演示</dt>
            <dd className={st.scanTip}>{tip}</dd>
            <dd>
              <img
                alt="注册试用"
                src={CDNBASE + "/src/qrcode/kuku_demo_dark.gif"}
              />
            </dd>
          </dl>
        )}
        {!isMobile && (
          <dl>
            <dt>注册试用</dt>
            <dd className={st.scanTip}>{tip}</dd>
            <dd>
              <img alt="注册试用" src={CDNBASE + "/src/qrcode/user_dark.gif"} />
            </dd>
          </dl>
        )}
        <dl>
          <dt>客服微信</dt>
          <dd className={st.scanTip}>{tip}</dd>
          <dd>
            <img alt="客服微信" src={CDNBASE + "/src/qrcode/kumei_dark.gif"} />
          </dd>
        </dl>
      </div>
      <div className={st.divider}></div>
      <div className={st.copyright}>
        <a target="_blank" href="https://beian.miit.gov.cn/">
          鄂ICP备2023007419号-1
        </a>
        <span>©2017~2024 酷课约课系统 kukecloud.com</span>
      </div>
      <div className={st.links}>
        <Link to="https://dc.kukecloud.com">酷课场地预订系统</Link>
      </div>
    </footer>
  )
}

export default Footer
