const defaultSearchFunction = (query, value, { boost = 1, fuzzy = 0 }) => {
  if (typeof value === "number") {
    value = value.toString()
  }
  if (typeof value !== "string") {
    return 0
  }
  const boostPower = boost > 1 ? Math.log(boost + 1) / Math.log(2) : 1
  let score = 0
  if (query.length <= 2) {
    score = value.includes(query) ? 1.2 : 0
  } else {
    //将query拆分成单个字符，然后分别在value中查找，每查到一个0.1分
    //另外设计一种连续字符匹配的加权算法，连续匹配的字符越多，分数越高
    //比如query为"abcw"，value为"aebdefgc"，则匹配字符为3个得0.3分，连续匹配的字符为0个，rate为0，最终分数为0.3 + （0.5*0) = 0.3
    //比如query为"abc"，value为"abqcdeqfg"，则匹配字符为3个得0.3分，连续匹配的字符为2个，rate为2，最终分数为0.3 + （0.5*2) = 1.3

    let matchCount = 0
    let rate = 0
    let matchIndexArr = []
    for (let i = 0; i < query.length; i++) {
      const queryChar = query[i]
      const r = new RegExp(queryChar, "gi")
      let flag = false
      while (r.exec(value)) {
        flag = true
        matchIndexArr.push(r.lastIndex - 1)
      }
      if (flag) {
        matchCount++
      }
    }
    if (matchCount > 0) {
      for (let i = 0; i < matchIndexArr.length - 1; i++) {
        const index1 = matchIndexArr[i]
        const index2 = matchIndexArr[i + 1]
        if (index2 - index1 === 1) {
          rate++
        }
      }
    }
    score = matchCount * 0.1 + rate * 0.8
    if (score < 0.2) {
      score = 0
    }
  }

  //   const fuzzyScore = fuzzy > 0 ? score * Math.pow(0.5, fuzzy) : 0
  //   console.log(score, boostPower)
  return Math.pow(score, boostPower)
}

const defaultScoreFunction = (
  query,
  fields,
  searchFunction,
  { boost = {}, fuzzy = 0 }
) => {
  const scores = fields.map(({ field, value }) => {
    const boostVal = field in boost ? boost[field] : 1
    return {
      field,
      score: searchFunction(query, value, {
        boost: boostVal,
        fuzzy,
      }),
    }
  })
  return scores
    .filter(({ score }) => score > 0)
    .reduce(
      (total, { score, field }) => {
        return {
          weight: total.weight + score,
          fields: [...total.fields, field],
        }
      },
      { weight: 0, fields: [] }
    )
}
const defaultExtractField = (doc, fieldName) => {
  const value = doc[fieldName]
  if (typeof value === "string") {
    return value
  }
  if (Array.isArray(value)) {
    return value.join("--")
  }
  return ""
}
class MiniSearch {
  constructor(options) {
    this._options = options
    this._searchableFields = options.fields
    this._searchIndex = {}
    this._idField = options.idField || "id"
    this._searchFunction = options.searchFunction || defaultSearchFunction
    this._searchOptions = options.searchOptions || {}
    this._extractField = options.extractField || defaultExtractField
    this._scoreFunction = options.scoreFunction || defaultScoreFunction
  }
  addAll(docs) {
    if (!docs || !Array.isArray(docs) || docs.length === 0) {
      // console.warn("No docs to add")
      return
    }
    if (!Array.isArray(docs)) {
      docs = [docs]
    }
    docs.forEach(doc => this.add(doc))
  }
  add(doc) {
    const id = doc[this._idField]
    if (id in this._searchIndex) {
      this.remove(id)
    }
    const fields = this._searchableFields.map(field => ({
      field,
      value: this._extractField(doc, field), // extract the value for the field
    }))
    this._searchIndex[id] = {
      id,
      fields,
    }
  }
  remove(id) {
    delete this._searchIndex[id]
  }
  search(query, options = {}) {
    query = query.trim()
    const results = Object.values(this._searchIndex)
      .map(doc => ({
        id: doc.id,
        query,
        score: this._scoreFunction(query, doc.fields, this._searchFunction, {
          ...this._searchOptions,
          ...options,
        }),
      }))
      .filter(({ score }) => score.weight > 0)
      .sort((a, b) => b.score.weight - a.score.weight)
    return results
  }
  toJSON() {
    return JSON.stringify(this._searchIndex)
  }
  static loadJSON(jsonString, options = {}) {
    const searchIndex = JSON.parse(jsonString)
    const miniSearch = new MiniSearch(options)
    miniSearch._searchIndex = searchIndex
    return miniSearch
  }
}
export default MiniSearch
