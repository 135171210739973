/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, keywords = [], lang, meta, title, author, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            author
            copyright
            siteUrl
          }
        }
      }
    `
  )

  const metaKeywords = [
    ...new Set([...keywords, ...(site.siteMetadata.keywords || "").split(",")]),
  ].filter(Boolean)

  const metaDescription = description || site.siteMetadata.description
  const metaAuthor = author || site.siteMetadata.author
  const metaImage = image || `http://cdn.kukecloud.com/cdn/m/me-3lcq180y901zu`
  const metaUrl = site.siteMetadata.siteUrl

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: metaKeywords.join(","),
        },
        {
          name: `author`,
          content: metaAuthor,
        },
        {
          name: `copyright`,
          content: site.siteMetadata.copyright,
        },
        {
          name: `robots`,
          content: 'index,follow'
        },
        // Open Graph
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`, 
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `og:site_name`,
          content: site.siteMetadata.title,
        },
        // Twitter Card
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: metaAuthor,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `zh`,
  meta: [],
  description: ``,
  keywords: [],
  image: ``,
}

SEO.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  author: PropTypes.string,
  image: PropTypes.string,
}

export default SEO
