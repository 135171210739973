let timeID = 0

export function cool(fn, time = 300) {
  clearTimeout(timeID)
  timeID = setTimeout(fn, time)
}
// export function getPageQuery() {
//   if (typeof window === "undefined") return {}

//   const result = {}
//   const query = decodeURIComponent(window.location.search)
//   console.log(query)
//   if (query) {
//     query
//       .substring(1)
//       .split("&")
//       .forEach(v => {
//         const a = v.split("=")
//         if (a[1]) {
//           result[a[0]] = a[1]
//         }
//       })
//   }

//   return result
// }
export function hashCode(str) {
  return (str + "rebase")
    .split("")
    .reduce((acc, cur) => ((acc << 5) - acc + cur.charCodeAt(0)) | 0, 0)
    .toString(36)
    .substr(1)
}
export function isInWx() {
  if (typeof navigator !== "object") return false

  const ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("micromessenger") !== -1
}
