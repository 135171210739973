export const CDNBASE = `https://cdn.kukecloud.com/assets/articles`

export const catMap = {
  contract: "酷课协议",
  FAQ: "酷课问答",
  operation: "运营小窍门",
  kkguide: "管理端使用指南",
  kkflguide: "学员端使用指南",
  upgrade: "酷课系统更新",
  kkNews: "酷课动态",
  
}

export const tagColorMap = {
  酷课功能: "#ffd985",
  运营技巧: "#70d4f3",
  统计分析: "#ffadd2",
  公众号: "#78cc99",
  会员卡: "#ffa6b3",
  拓客: "#ffb58b",
  会员管理: "#ff909b",
  视频教程: "#adb1f2",
}
