/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ComSvgs } from "./svgs"
import { isInWx } from "../utils/utils"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import SideBar from "./sideBar"
import TopSearchBar from "./top_search_bar"
import "./css/layout.css"
import "./css/common.css"

function useIsMobile(breakpoint = 1140) {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= breakpoint);
    };

    // Check on mount
    checkMobile();

    // Add debounced resize listener
    let timeoutId;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(checkMobile, 100);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeoutId);
    };
  }, [breakpoint]);

  return isMobile;
}

const Layout = function ({ children, cur, showSBar, bg, stickyHeader }) {
  const isWx = isInWx()
  const isMobile = useIsMobile()

  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { isMobile });
    }
    return child;
  });

  return (
    <>
      <ComSvgs />
      <Header cur={cur} sticky={stickyHeader} isWx={isWx} isMobile={isMobile} />
      {isMobile && <TopSearchBar topBar />}
      <main style={{ backgroundColor: bg || "#fff" }}>{childrenWithProps}</main>
      <Footer isMobile={isMobile} isWx={isWx} />
      {/* {showSBar && !isMobile && ( */}
      { !isMobile && (
        <SideBar wrapClass="fixRight" isWx={isWx} isMobile={isMobile} />
      )}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export { useIsMobile }
export default Layout
