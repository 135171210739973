import React from "react"
import PropTypes from "prop-types"

const Svg = ({ svgClass, id, datatyp, ...rest }) => (
  <svg data-typ={datatyp} className={svgClass} {...rest}>
    <use
      data-typ={datatyp}
      xlink="https://www.w3.org/1999/xlink"
      href={"#" + id}
    ></use>
  </svg>
)

Svg.propTypes = {
  svgClass: PropTypes.string,
  id: PropTypes.string,
}

Svg.defaultProps = {
  svgClass: ``,
  id: ``,
}

export default Svg
