import React, { Component } from "react"
import MiniSearch from "../../lib/minisearch"

import allData from "../../sdata/all.json"
// import fqa from "../../sdata/fqa.json"
// import guide from "../../sdata/guide.json"
// import intro from "../../sdata/intro.json"
// import posts from "../../sdata/posts.json"
// import advance from "../../sdata/advance.json"
import st from "./css/search.module.scss"
import Svg from "./svg"
import { Link } from "gatsby"
import classNames from "classnames"
// import { Highlight } from "instantsearch.js/es/helpers/components"

// You can set the default search options upon initialization
const defaultOpt = {
  fields: ["title", "content", "desc", "tags"],
  searchOptions: {
    boost: { title: 5, desc: 2 },
    fuzzy: 0,
  },
}
// const sdata = {
//   fqa,
//   guide,
//   intro,
//   posts,
//   advance,
// }
const catMap = {
  posts: "帮助文档",
  guide: "使用指南",
  fqa: "常见问题",
  intro: "产品介绍",
  advance: "酷课优势",
}

export default class Search extends Component {
  state = {
    value: "",
    result: [],
  }
  componentDidMount() {
    this.initSearch()
    if (this.props.topBar) {
      this.refs.searchInput.addEventListener("keydown", e => {
        if (e.keyCode === 13) {
          this.refs.searchInput.blur()
        }
      })
    }
  }
  initSearch = () => {
    const { searchOpt = defaultOpt } = this.props
    this[`miniSearch_all`] = new MiniSearch(searchOpt)
    this[`miniSearch_all`].addAll(allData)
  }
  search = value => {
    if (!value.length) {
      this.setState({
        result: [],
      })
      return
    }
    const result = this[`miniSearch_all`].search(value)
    const limit = 9
    this.setState({
      result: result.slice(0, limit),
    })
  }
  hdSearch = e => {
    const value = (e.target.value || "").trim()
    this.setState({ value })
    this.search(value)
  }
  hdBlur = e => {
    setTimeout(() => {
      this.setState({
        value: "",
        result: [],
      })
    }, 300)
  }

  render() {
    const { value, result } = this.state
    const { navBar, topBar, orinCat } = this.props
    return (
      <div
        className={classNames(
          st.wrapper,
          { [st.navBar]: navBar },
          { [st.topBar]: topBar }
        )}
      >
        <div className={st.inputWrap}>
          <input
            ref="searchInput"
            type="text"
            className={st.input}
            onChange={this.hdSearch}
            onBlur={topBar ? undefined : this.hdBlur}
            value={value}
            placeholder={`搜索关键词`}
          />
          {!!value.length ? (
            <Svg
              id="close"
              svgClass={st.icon}
              style={{ cursor: "pointer" }}
              onClick={this.hdBlur}
            />
          ) : (
            <Svg id="search" svgClass={st.icon} />
          )}
        </div>
        {value.length > 0 && (
          <div className={st.result}>
            {result.length > 0 ? (
              result.map((v, i) => {
                const item = allData.find(item => item.id === v.id)
                const { id, cat, title, content, desc, tags } = item
                const hasDesc = v.score.fields.includes("desc")
                const hasTags = v.score.fields.includes("tags")
                const hasCont = v.score.fields.includes("content")
                const isGuidePage =
                  cat === "guide" && window.location.pathname === "/"
                // console.log(isGuidePage, window.location.pathname)
                const linkEl = (
                  <Highlight
                    abort={!v.score.fields.includes("title")}
                    query={v.query}
                    topBar={topBar}
                    text={title}
                    color="#4d3800"
                  />
                )
                return (
                  <div className={st.raw} key={v.id}>
                    <div className={st.cat}>{catMap[cat]}</div>
                    <div className={st.list} key={id}>
                      <div
                        className={st.title}
                        onClick={topBar ? this.hdBlur : undefined}
                      >
                        {isGuidePage ? (
                          <a href={id}>{linkEl}</a>
                        ) : (
                          <Link to={id}>{linkEl}</Link>
                        )}
                      </div>
                      {hasTags && (
                        <div className={st.tags}>
                          {
                            <Highlight
                              query={v.query}
                              text={tags}
                              topBar={topBar}
                            />
                          }
                        </div>
                      )}
                      {hasDesc && (
                        <div className={st.tags}>
                          {
                            <Highlight
                              query={v.query}
                              text={desc}
                              topBar={topBar}
                            />
                          }
                        </div>
                      )}
                      <div className={st.cont}>
                        {
                          <Highlight
                            abort={!hasCont}
                            query={v.query}
                            text={content}
                            slice={!orinCat}
                            topBar={topBar}
                          />
                        }
                      </div>
                    </div>
                  </div>
                )
              })
            ) : (
              <div className={st.noResult}>暂无搜索结果</div>
            )}
          </div>
        )}
      </div>
    )
  }
}

function Highlight({ abort, query, text, slice, color = "#777", topBar }) {
  if (abort) {
    return <span>{text}</span>
  }
  if (Array.isArray(text)) {
    text = text.join("； ")
  }
  if (slice) {
    text = sliceStr(text, query, topBar)
  }

  return (
    <span
      dangerouslySetInnerHTML={{
        __html: text.replace(
          new RegExp(query.split("").join("|"), "gi"),
          `<b style='color: ${color};'>$&</b>`
        ),
      }}
    ></span>
  )
}
function sliceStr(text, query, topBar) {
  const n = topBar ? 48 : 83
  //截取第一个match前20个字符，后50/n个字符
  if (text.length < n) return text
  const match = text.match(new RegExp(query, "i"))
  if (match) {
    const { index } = match
    const start = index - 20 > 0 ? index - 20 : 0
    const nn = n - (index - start)
    const end = index + nn < text.length ? index + nn : text.length
    let newText = text.slice(start, end)
    if (start > 0) {
      newText = "..." + newText
    }
    if (end < text.length) {
      newText = newText + "..."
    }
    return newText
  } else {
    return text.slice(0, n)
  }
}
