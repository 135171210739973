import React from "react"
import st from "./css/top_search_bar.module.scss"
import Search from "./search"

export default function TopSearchBar({ cat, topBar }) {
  return (
    <div className={st.wrapper}>
      <div className={st.searchBar}>
        <div className={st.searchWrapper}>
          <Search cat={cat} topBar={topBar} />
        </div>
      </div>
    </div>
  )
}
